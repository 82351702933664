import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const msnry = new Masonry(this.element, {
      percentPosition: true
    });

    $(this.element).imagesLoaded().progress(function() {
      msnry.layout();
    })
  }
}
