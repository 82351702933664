import { Controller } from "stimulus"
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js"
// import { English } from "flatpickr/dist/l10n/en.js"

export default class extends Controller {

  connect() {
    var form = this.element
    var date_input = this.element.querySelector('input[name="date"]')
    var category_input = this.element.querySelector('select[name="category"]')
    var cinema_input = this.element.querySelector('select[name="cinema"]')
    var medium_input = this.element.querySelector('select[name="medium"]')

    var options = {
      dateFormat: 'd.m.Y',
      enable: date_input.dataset.enabledDates.split(','),
      wrap: true
    };
    if(document.documentElement.lang == 'ru') {
      options.locale = Russian
    }
    flatpickr(this.element.querySelector('.flatpickr'), options);

    [date_input, category_input, cinema_input, medium_input].forEach(function(input) {
      if(input) {
        input.addEventListener('change', function() {
          $(form).trigger('submit');
        })        
      }
    })        
  }
  
}