import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "itemsWrapper",
    "item",
    "prevButton",
    "nextButton",
  ]

  connect() {
    $(this.element).imagesLoaded().progress(function() {
      this.align_arrows_vertically()
    }.bind(this))
  }

  align_arrows_vertically() {
    if (this.hasNextButtonTarget && this.hasPrevButtonTarget) {
      const y = this.getArrowsY()
      $(this.nextButtonTarget).css('top', y)
      $(this.prevButtonTarget).css('top', y)
    }
  }

  getArrowsY() {
    let minY = 1000
    let maxY = 0
    this.element.querySelectorAll('img').forEach(el => {
      const pos = $(el).position()
      const elementMaxY = pos.top + $(el).height()

      if (pos.top < minY) {
        minY = pos.top
      }

      if (elementMaxY > maxY) {
        maxY = elementMaxY
      }
    })
    return (minY + maxY) * 0.5
  }

  prev(e) {
    const items = this.itemTargets
    const prevItem = items.reverse().find(function(item) {
      const x = item.offsetLeft - this.itemsWrapperTarget.scrollLeft //- 15
      return x < 0
    }.bind(this))
    // console.log("prevItem", prevItem, prevItem.offsetLeft)
    if (prevItem) {
      this.itemsWrapperTarget.scrollTo({left: prevItem.offsetLeft, top: 0, behavior: 'smooth'});
    }
  }

  next(e) {
    let currentItem = undefined
    const nextItem = this.itemTargets.find(function(item) {
      const x = item.offsetLeft - this.itemsWrapperTarget.scrollLeft //- 15
      return x > 0
    }.bind(this))
    // console.log(nextItem, nextItem.offsetLeft)
    if (nextItem) {
      this.itemsWrapperTarget.scrollTo({left: nextItem.offsetLeft, top: 0, behavior: 'smooth'});
    }
  }

  scrollHandler() {
    this.prevButtonTarget.classList.toggle("swiper-button-disabled", !(this.itemsWrapperTarget.scrollLeft > 0))
    // console.log("scrollWidth", this.itemsWrapperTarget.scrollWidth - this.itemsWrapperTarget.offsetWidth, this.itemsWrapperTarget.scrollLeft)
    this.nextButtonTarget.classList.toggle("swiper-button-disabled", !(this.itemsWrapperTarget.scrollWidth - this.itemsWrapperTarget.offsetWidth > this.itemsWrapperTarget.scrollLeft))
  }

  get itemWidth() {
    return this.itemTargets[0].offsetWidth
  }
}
