// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $('body').scrollspy({
      target: '[data-controller="scrollspy"]'
      // offset: 140
    });

    $('body').on('activate.bs.scrollspy', function(e) {
      console.log('scrollspy', e)
    })
    console.log('scrollspy controller connected')
  }

  disconnect() {
    $('body').scrollspy('dispose')
    console.log('scrollspy controller disconnected')
  }
}
