import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    var self = this
    this.targets.find("filter-name").addEventListener('keyup', function(e) {
      self.render()
    })
    this.render()
  }
  render() {
    
    this.targets.findAll("genre").forEach((element, index) => {
      var active_flag = element.dataset.value == this.genre
      element.classList.toggle("btn-primary", active_flag)
      element.classList.toggle("btn-secondary", !active_flag)
    })
    
    this.targets.findAll("tag").forEach((element, index) => {
      var active_flag = (!element.dataset.value && !this.tag) || (element.dataset.value == this.tag)
      element.classList.toggle("btn-primary", active_flag)
      element.classList.toggle("btn-secondary", !active_flag)
    })
    
    this.targets.findAll("medium").forEach((element, index) => {
      var active_flag = element.dataset.value == this.medium
      element.classList.toggle("btn-primary", active_flag)
      element.classList.toggle("btn-secondary", !active_flag)
    })
    
    var filter_name = this.targets.find("filter-name").value.toLowerCase()
    
    this.targets.findAll("element").forEach((element, index) => {
      var element_name = element.dataset.name.toLowerCase()
      var hidden_flag = element_name.indexOf(filter_name) == -1
      if(!hidden_flag && this.medium != '0' && this.medium != element.dataset.medium) {
        hidden_flag = true
      }
      if(!hidden_flag && this.tag != '0' && this.tag != element.dataset.tag) {
        hidden_flag = true
      }
      if(!hidden_flag && this.genre != '0' && this.genre != element.dataset.genre) {
        hidden_flag = true
      }
      element.classList.toggle("screenings-screening-component--hidden", hidden_flag)
    })
  }
  
  tagClicked(event) {
    // console.log('categoryClicked', event)
    this.tag = event.target.dataset.value
  }
  
  get tag() {
    if (this.data.has("tag")) {
      return this.data.get("tag")
    } else {
      return 0
    }
  }
  
  set tag(value) {
    this.data.set("tag", value)
    this.render()
  }
  
  mediumClicked(event) {
    this.medium = event.target.dataset.value
  }
  
  set medium(value) {
    this.data.set("medium", value)
    this.render()
  }
  
  get medium() {
    if (this.data.has("medium")) {
      return this.data.get("medium")
    } else {
      return 0
    }
  }
  
  genreClicked(event) {
    this.genre = event.target.dataset.value
  }
  
  set genre(value) {
    this.data.set("genre", value)
    this.render()
  }
  
  get genre() {
    if (this.data.has("genre")) {
      return this.data.get("genre")
    } else {
      return 0
    }
  }
  
}