import { Controller } from "stimulus"
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js"
// import { English } from "flatpickr/dist/l10n/en.js"

export default class extends Controller {
  static targets = [ "flatpickr", "dateInput", "cinemaSelect", "mediumSelect", "noScreeningsMessage" ]

  connect() {
    if(!this.element.querySelector('.schedule-filter')) { return }

    var options = {
      dateFormat: 'd.m.Y',
      wrap: true
    };

    if (this.dateInputTarget.dataset.enabledDates) {
      options.enable = this.dateInputTarget.dataset.enabledDates.split(',')
    }

    if(document.documentElement.lang == 'ru') {
      options.locale = Russian
    }
    flatpickr(this.flatpickrTarget, options);

    this.filterScreenings()
  }

  filter() {
    this.filterScreenings()
  }

  filterScreenings() {
    var selectedDate = this.dateInputTarget.value
    var selectedCinema = this.cinemaSelectTarget.value
    var selectedMedium = this.mediumSelectTarget.value
    // console.log('selectedMedium', selectedMedium)
    var hasScreenings = false
    this.element.querySelectorAll('.embeddable-schedule--date-screenings').forEach((dateScreenings) => {
      var showDateScreenings = false
      dateScreenings.querySelectorAll('.embeddable-schedule--time-release-cinema-screenings').forEach((timeReleaseCinemaScreenings) => {
        var showTimeReleaseCinemaScreenings = true
        if(showTimeReleaseCinemaScreenings && selectedDate && selectedDate != timeReleaseCinemaScreenings.dataset.date) {
          showTimeReleaseCinemaScreenings = false
        }
        if(showTimeReleaseCinemaScreenings && selectedCinema && selectedCinema != timeReleaseCinemaScreenings.dataset.cinema) {
          showTimeReleaseCinemaScreenings = false
        }
        if(showTimeReleaseCinemaScreenings && selectedMedium && selectedMedium == 2 && timeReleaseCinemaScreenings.dataset.medium != 2) {
          showTimeReleaseCinemaScreenings = false
        }
        if(showTimeReleaseCinemaScreenings && selectedMedium && selectedMedium == 3 && timeReleaseCinemaScreenings.dataset.first != 1) {
          showTimeReleaseCinemaScreenings = false
        }
        timeReleaseCinemaScreenings.classList.toggle("embeddable-schedule--hidden", !showTimeReleaseCinemaScreenings)
        if(showTimeReleaseCinemaScreenings) {
          showDateScreenings = true
        }
      })
      dateScreenings.classList.toggle("embeddable-schedule--hidden", !showDateScreenings)
      if(showDateScreenings) {
        hasScreenings = true
      }
    })
    this.noScreeningsMessageTarget.classList.toggle("embeddable-schedule--hidden", hasScreenings)
    this.flatpickrTarget.classList.toggle("selected", selectedDate != '')
    this.cinemaSelectTarget.classList.toggle("selected", selectedCinema != '')
    this.mediumSelectTarget.classList.toggle("selected", selectedMedium != '')
  }
}