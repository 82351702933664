import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "scheduleItemHeader" ]

  connect() {
    $(function() {
      this.setEqualHeights()
    }.bind(this))

    $(window).resize(function() {
      this.setEqualHeights()
    }.bind(this))

    this.setEqualHeights()
  }

  setEqualHeights() {
    this.scheduleItemHeaderTargets.forEach((el) => {
      $(el).height('auto')
    })

    if($(window).width() > 768) { // sm 576
      const heights = this.scheduleItemHeaderTargets.map((el) => {
        return $(el).height()
      })
      const maxHeight = Math.max(...heights)

      this.scheduleItemHeaderTargets.forEach((el) => {
        $(el).height(maxHeight)
      })
    }
  }
}
