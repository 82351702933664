import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var allowClear = $(this.element).find('option:first-child').val() == ''
    var dropdownParent = $(this.element).closest('.modal-body')
    if(dropdownParent.length == 0) {
      dropdownParent = $('body')
    }
    console.log('dropdownParent', dropdownParent)
  	$(this.element).select2({
      theme: "bootstrap",
      allowClear: allowClear,
      dropdownParent: dropdownParent,
      placeholder: 'Выберите персону…',
      templateResult: function(state) {
        // console.log(state);
        var html = '';
        html += '<span>';
        html += state.text
        if(state.brief) {
          // console.log('here');
          html += '<br>';
          html += '<small style="opacity: 0.8;">';
          html += state.brief;
          html += '</small>'
        }
        html += '</span>';
        return $(html);
      },
      ajax: {
        url: '/admin/people',
        dataType: 'json',
        data: function (params) {
          return {
            q: { name_cont: params.term }
          }
        },
        processResults: function (results) {
          // console.log(results);
          // Tranforms the top-level key of the response object from 'items' to'results'
          return {
            results: $.map(results, function(result) {
              return {
                id: result.id,
                text: result.last_first_name_ru,
                brief: result.brief_ru
              };
            })
          };
        }
      }
    });
    // $(this.element).on('select2:unselecting', function(e) {
    //   console.log('select2 event', e)
    // });
  }
}
