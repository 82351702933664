import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tab", "pane" ]

  switch(e) {
    const tab = e.target.getAttribute("data-tab")

    this.tabTargets.forEach(tabItem => {
      tabItem.classList.toggle("active", tabItem.getAttribute("data-tab") == tab)
    })

    this.paneTargets.forEach(paneItem => {
      paneItem.classList.toggle("active", paneItem.getAttribute("data-tab") == tab)
    })
  }
}
