import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item" ]

  connect() {
    // console.log("same y equal height controller")
    $(this.element).imagesLoaded(this.makeEqual.bind(this))
    this.makeEqual()
  }

  makeEqual() {
    let y = undefined
    let sameYItems = []
    this.itemTargets.forEach(item => {
      const pos = $(item).offset()

      if (pos.top != y) {
        this.makeEqualRow(sameYItems)
        y = pos.top
        sameYItems = []
      }

      sameYItems.push(item)
    })
    this.makeEqualRow(sameYItems)
  }

  makeEqualRow(items) {
    // console.log("makeEqualRow", items)
    items.forEach(item => {
      $(item).css("minHeight", "auto")
    })

    const maxHeight = items.reduce(function(accumulator, item) {
      const itemHeight = $(item).height()
      return itemHeight > accumulator ? itemHeight : accumulator
    }, 0)
    // console.log("maxHeight", maxHeight)

    items.forEach(item => {
      $(item).css("minHeight", maxHeight)
    })
  }
}
