import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'contextMenu',
    'contextMenuFilmMenu',
    'navItem',
    'items',
    'item',
    'navSubItem'
  ]

  connect() {
    this.preSelectFilm(this.startIndex)
    this.initFilmsCarousel()
    this.initStickyMenu()
    this.initScrollSpy()
  }

  preSelectFilm(index) {
    this.navItemTargets[index].classList.add('active')
    this.contextMenuFilmMenuTargets[index].classList.add('active')
    this.itemTargets[index].classList.add('active')
  }

  selectFilm(index) {
    this.navItemTargets.forEach((el, i) => {
      el.classList.toggle("active", index == i)
    })
    this.contextMenuFilmMenuTargets.forEach((el, i) => {
      el.classList.toggle("active", index == i)
    })
    $(this.itemsTarget).carousel(index)
  }

  initFilmsCarousel() {
    $(this.itemsTarget).carousel({
      interval: false,
      wrap: false,
      touch: false
    })
  }

  initStickyMenu() {
    var params = {
      topSpacing: parseInt(this.contextMenuTarget.getAttribute('data-sticky-top-spacing')),
      zIndex: 100,
    };
    $(this.contextMenuTarget).sticky(params);

    var headerMargin = $('.header').height();
    var contextMenuMargin = $('.sticky-wrapper').height();
    $('a[id].anchor').css({
      display: 'block',
      position: 'relative',
      top: -(headerMargin + contextMenuMargin + 40), /* height of sticky navigation */
      visibility: 'hidden'
    });
  }

  initScrollSpy() {
    $('body').scrollspy({
      target: '.context-menu--films-menus',
      method: 'offset'
      // offset: 140
    });
  }

  get startIndex() {
    const match = window.location.hash.match(/#(.+?)--.+/)
    if(match) {
      return this.indexFromSlug(match[1])
    }
    else {
      return 0
    }
  }

  indexFromSlug(slug) {
    // console.log('indexFromSlug', slug)
    var index = null
    this.navItemTargets.forEach((el, i) => {
      if(el.getAttribute('data-slug') == slug) {
        index = i
        return
      }
    })
    return index
  }

  switchFilm(e) {
    const index = parseInt(e.target.getAttribute('data-index'))
    // console.log('switch film', index)
    this.selectFilm(index)
    //     e.preventDefault()

//     const filmIndex = this.indexFromSlug(e.target.getAttribute('data-multiple-films-slug'))
//     // console.log('filmIndex', filmIndex)

//     this.navItemTargets.forEach((el, i) => {
//       el.classList.toggle('active', el == e.target)
//     })

//     this.navSubItemTargets.forEach((el, i) => {
//       el.classList.toggle('d-none', i != filmIndex)
//     })

//     // console.log('filmIndex', filmIndex)
//     $(this.itemsTarget).trigger('to.owl.carousel', [filmIndex, 700]);
  }
}