import { Controller } from 'stimulus'

export default class extends Controller {   
  static targets = [
    'submitButton',
  ]
  
  connect() {
    console.log('test')
  }
  
  agreementChanged(event) {
    console.log('here')
    if (event.target.checked) {
      this.submitButtonTarget.removeAttribute('disabled')
    } else {
      this.submitButtonTarget.setAttribute('disabled', true)
    }
  }
}