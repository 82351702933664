import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "date",
    'screening',
  ]

  dateChanged(e) {
    const date = $(e.target).closest("button")[0].getAttribute("data-date")

    this.dateTargets.forEach(el => {
      el.classList.toggle("selected", el.getAttribute("data-date") == date)
    })

    this.screeningTargets.forEach(el => {
      const shown = el.getAttribute("data-date") == date
      el.classList.toggle("d-none", !shown)
    })
  }
}