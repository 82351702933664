import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "next", "prev", "container", "pagination" ]
  static values = {
    autoHeight: Boolean,
    loop: Boolean,
    autoplay: Number,
    slidesPerView: Number,
    spaceBetween: Number,
  }

  connect() {
    const options = {
      autoHeight: true,
      loop: this.loopValue,
      speed: 600,
      delay: 4000,
      slidesPerView: 1,
      spaceBetween: this.spaceBetweenValue,

      breakpoints: {
        576: {
          slidesPerView: this.slidesPerViewValue,
          autoHeight: this.autoHeightValue,
        }
      },
    }

    if (this.hasNextTarget && this.hasPrevTarget) {
      options.navigation = {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      }
    }

    if (this.hasPaginationTarget) {
      options.pagination = {
        el: this.paginationTarget
      }
    }

    if (this.autoplayValue > 0) {
      options.autoplay = {
        delay: this.autoplayValue
      }
    }

    const el = this.hasContainerTarget ? this.containerTarget : this.element
    this.swiper = new Swiper(el, options)
  }

  next() {
    this.swiper.slideNext()
  }

  prev() {
    this.swiper.slidePrev()
  }
}
