import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).selectpicker({
      liveSearch: true,
      noneSelectedText: 'Выберите фильм…'
    })

    $(this.element).ajaxSelectPicker({
      ajax: {
        type: 'GET',
        url: '/admin/titles/search',
        //data: {
        //  by_name: '{{{q}}}'
        //}
      },
      // locale: {
      //   emptyTitle: 'Выберите фильм…'
      // },
      preprocessData: function(data) {
        return $.map(data, function(title) {
          var values = [title.name_ru]
          if(title.year) {
            values.push('Год: ' + title.year)
          }
          if(title.theatre) {
            values.push('Театр: ' + title.theatre.name_ru)
          }
          values.push('ID: ' + title.id)
          return {
            value: title.id,
            text: values.join(' | ')
          }
        })
      }
    })
  }
}
