import { Controller } from "stimulus"
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static targets = [ "swiper" ]

  connect() {
    if ($(this.swiperTarget).find('.swiper-slide').length > 1) {
      const options = {
        pagination: {
          el: '.banners .swiper-pagination',
        },
        navigation: {
          nextEl: '.banners .swiper-button-next',
          prevEl: '.banners .swiper-button-prev',
        },
      }
      new Swiper(this.swiperTarget, options)
    }
  }
}