import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    id: String,
  }

  click(e) {
    e.preventDefault()
    ticketManager.movieSession('0f57b149-98b9-4146-87ef-9a10ebb53f03', this.idValue);
  }
}
