import { Controller } from "stimulus"

export default class extends Controller {
  static values = { otps: Array, index: Number, injectedHtml: String, ccLang: String }

  connect() {
    // console.log('vdocipher_player connected')
  }

  vdoCipherAPIReady() {
    this.loadPlayer(this.otpsValue[this.indexValue], false)
  }

  loadPlayer(otp, autoplay) {
    this.activeVideo = new window.VdoPlayer({
      otp: otp.otp,
      playbackInfo: otp.playbackInfo,
      theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
      autoplay: autoplay,
      container: this.element,
      ccLang: this.ccLangValue,
    });
    this.activeVideo.addEventListener("ended", function() {
    	this.videoEnded()
    }.bind(this));
  }

  videoEnded() {
    if (this.indexValue < this.otpsValue.length - 1) {
      this.indexValue += 1
      this.loadPlayer(this.otpsValue[this.indexValue], true)
    } else {
      console.log('THE END')

      if (this.injectedHtmlValue) {
        console.log('injectThemeHtml')
        this.activeVideo.injectThemeHtml(this.injectedHtmlValue)
      }
    }
  }
}