import { Controller } from "stimulus"

export default class extends Controller {
    
  static targets = [ 'cinema', 'medium', 'screening', 'date' ]
    
  changeCity(e) {
    window.location = e.target.value
  }
  
  changeCinema(e) {
    this.render()
  }
  
  changeMedium(e) {
    this.render()
  }
  
  render() {
    var selected_cinema_id = this.cinemaTarget.value
    var selected_medium = this.mediumTarget.value
    
    this.screeningTargets.forEach((element, index) => {
      element.classList.toggle("hidden", !this.screeningIncluded(element, selected_cinema_id, selected_medium))
    })
    
    this.dateTargets.forEach((element, index) => {
      var childrenCount = $(element).find('.schedule-component--row:not(.hidden)').length
      var active_flag = childrenCount > 0
      element.classList.toggle("hidden", !active_flag)
    })
  }
  
  screeningIncluded(element, cinema_id, medium) {
    if(cinema_id != '' && element.dataset.cinema != cinema_id) {
      return false
    }
    if(medium != '' && element.dataset.medium != medium) {
      return false
    }
    return true
  }
  
}