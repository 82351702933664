import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    var self = this
    this.targets.find("filter-name").addEventListener('keyup', function(e) {
      // console.log('filter-name', e.target.value)
      self.render()
    })
    this.render()
  }
  render() {
    console.log('country', this.country)
    
    this.targets.findAll("country-btn").forEach((element, index) => {
      var active_flag = element.dataset.value == this.country
      element.classList.toggle("active", active_flag)
    })
    
    this.targets.findAll("country").forEach((element, index) => {
      var hidden_flag = element.dataset.value != this.country
      element.classList.toggle("city_selector_component__country__hidden", hidden_flag)
    })
        
    var filter_name = this.targets.find("filter-name").value.toLowerCase()
    
    this.targets.findAll("city").forEach((element, index) => {
      var element_name = element.dataset.name.toLowerCase()
      var hidden_flag = element_name.indexOf(filter_name) == -1
      element.classList.toggle("city_selector_component__city__hidden", hidden_flag)
    })
  }
  
  countryClicked(event) {
    // console.log('categoryClicked', event)
    event.preventDefault()
    this.country = event.target.dataset.value
  }
  
  get country() {
    if (this.data.has("country")) {
      return this.data.get("country")
    } else {
      return null
    }
  }
  
  set country(value) {
    this.data.set("country", value)
    this.render()
  }
  
}