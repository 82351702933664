import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "next", "prev", "container", "equalHeight" ]
  static values = {
    slidesPerView: Number,
  }

  connect() {
    const options = {
      spaceBetween: 30,
      slidesPerView: 'auto',

      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: this.slidesPerViewValue,
        },
      },
    }

    if (this.hasNextTarget && this.hasPrevTarget) {
      options.navigation = {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      }
    }

    // console.log('swiper options', options)
    this.swiper = new Swiper(this.containerTarget, options)

    $(this.containerTarget).imagesLoaded().progress(function() {
      this.align_arrows_vertically()
    }.bind(this))

    $(window).on('resize', function() {
      this.makeEqualHeights()
    }.bind(this))
    this.makeEqualHeights()
  }

  align_arrows_vertically() {
    if (this.hasNextTarget && this.hasPrevTarget) {
      const y = this.getArrowsY()
      $(this.nextTarget).css('top', y)
      $(this.prevTarget).css('top', y)
    }
  }

  getArrowsY() {
    let minY = 1000
    let maxY = 0
    this.element.querySelectorAll('img').forEach(el => {
      const pos = $(el).position()
      const elementMaxY = pos.top + $(el).height()

      if (pos.top < minY) {
        minY = pos.top
      }

      if (elementMaxY > maxY) {
        maxY = elementMaxY
      }
    })
    return (minY + maxY) * 0.5
  }

  makeEqualHeights() {
    let h = 0
    this.equalHeightTargets.forEach(el => {
      $(el).css('height', 'auto')

      const elHeight = $(el).height()
      if (elHeight > h) {
        h = elHeight
      }
    })

    this.equalHeightTargets.forEach(el => {
      $(el).css('height', h)
    })
  }
}
